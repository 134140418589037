import { createI18n } from "vue-i18n";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    console.log(key)
    if (matched && matched.length > 1) {
      const locale = matched[1];
      console.log(locales(key))
      messages[locale] = locales(key);
    }
  });

  console.log(messages)

  return messages;
}

function setCookie(name, val, expire) {
  this.$cookies.set(name, val, expire);
}

function getCookie(name) {
  let val = this.$cookies.get(name);
  return val;
}

function exist(name) {
  this.$cookies.isKey(name);
}
function checkLocale() {
  return this.getCookie("locale");
}

export default createI18n({
  legacy: false,
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  globalInjection: true,
  messages: loadLocaleMessages()
});
