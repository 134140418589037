<template>
  <div class="container">
    <Home
        v-if="currentStep == 'intro'"
        @updateStep="setVideoStep"
    ></Home>
    <Video
        v-if="currentStep == 'video'"
        @updateStep="setCompassStep"
    ></Video>
    <Compass
        v-if="currentStep == 'compass'" @updateStep="setFinalStep"
    ></Compass>
    <Final
        v-if="currentStep == 'final'"
    ></Final>
  </div>
</template>

<script>
import Home from "./views/Home";
import Video from "./views/Video";
import Compass from "./views/Compass";
import Final from "./views/Final";

export default {
  data() {
    return {
      steps: ['intro', 'video', 'compass', 'final'],
      currentStep: 'intro',
    };
  },
  components: {
    Home,
    Video,
    Compass,
    Final
  },
  methods: {
    setVideoStep() {
      this.currentStep = 'video';
    },
    setCompassStep() {
      this.currentStep = 'compass';
    },
    setFinalStep() {
      this.currentStep = 'final';
    }
  }
}
</script>