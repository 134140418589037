<template>
  <div class="video-container">
    <div class="video__button" v-if="!isPlaying && !isEnded">
      <button @click="playVideo">
        <img src="@/assets/img/play-ico.svg" alt="">
      </button>
    </div>
    <div class="video__frame" v-show="isPlaying && !isEnded">
      <video src="@/assets/video/video.mp4" ref="videoRef"></video>
    </div>
    <div v-if="isEnded" class="video__next">
      <button class="btn" @click="goToNextStep">{{ $t('continue-to-the-ritual') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Video',
  components: {

  },
  data() {
    return {
      isEnded: false,
      isPlaying: false,
      isPaused: false,
    }
  },
  mounted(){
    this.$refs.videoRef.play();
    this.isPlaying = true;

    this.$refs.videoRef.addEventListener('ended', () => {
      this.isEnded = true;
      this.isPlaying = false;
      this.$refs.videoRef.webkitExitFullscreen();
    });
  },
  methods: {
    // playVideo() {
    //   this.$refs.videoRef.play();
    //   this.isPlaying = true;
    // },
    goToNextStep() {
      this.$emit('updateStep');
    },
  }
}
</script>
