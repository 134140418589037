<template>
  <div class="introduce">
    <img src="@/assets/img/discover-logo.png" alt="Discover">

    <SwitchLocale></SwitchLocale>

    <form class="form" @submit.prevent="submit">
      <div class="form-wrapper">
      <div class="form__row age__radio-label">
        <span>{{ $t('are-you-old') }}</span>
      </div>
      <div class="form__row age__radio-holder">
        <label class="form__radio-container">
          <input type="radio" name="adult" value="true" v-model="form.adult"/>
          <span class="form__radio"></span>
          <span>{{ $t('yes') }}</span>
        </label>
        <label class="form__radio-container">
          <input type="radio" name="adult" value="false" v-model="form.adult"/>
          <span class="form__radio"></span>
          <span>{{ $t('no') }}</span>
        </label>
      </div>
      <div class="form__row form__row-check">
        <label class="form__label-check">
          <input type="checkbox" v-model="form.acceptTerms"/>
          <span class="form__checkbox">
            <img src="@/assets/img/icon-check.svg" alt="checkbox"/>
          </span>
          <span>
            {{ $t('accept') }}
          </span>
        </label>
      </div>
        <div class="form__errors" v-if="form.showError">{{ form.msgError }}</div>
      </div>
      <button class="btn" >{{ $t('begin-your-journey') }}</button>
    </form>
  </div>
</template>

<script>
import SwitchLocale from "../components/SwitchLocale";

export default {
  name: 'Home',
  components: {
    SwitchLocale
  },
  props: {
    setStep: Function
  },
  data() {
    return {
      form: {
        adult: false,
        acceptTerms: false,
        showError: false,
        msgError: "",
      },
    }
  },
  methods: {
    submit() {
      this.showError = false;
      this.msgError = "";
      console.log(this.form.acceptTerms, this.form.adult)
      if (!this.form.adult) {
        this.form.showError = true;
        this.form.msgError = "You must be over 18 years old";
      } else if (!this.form.acceptTerms) {
        this.form.showError = true;
        this.form.msgError = "Please accept Terms & Conditions";
      } else {
        this.$emit('updateStep');
      }
    }
  }
}
</script>
