<template>
  <div class="final-container">
    <img src="@/assets/img/trivento-final-logo.png" class="final__logo" alt="Logo">
    <h6>{{ $t('final-text') }}</h6>
    <div class="final__line"></div>
    <h2>{{ $t('malbec') }}</h2>
    <p>{{ $t('malbec-text') }}</p>
    <div class="final__line"></div>
    <h2>{{ $t('luján-de-cuyo-essentials') }}</h2>
      <p>{{ $t('malbec-from-vistalba') }}<br>
        {{ $t('malbec-from-vistalba-info') }}</p>
      <p>{{ $t('malbec-from-las-compuertas') }}<br>
        {{ $t('malbec-from-las-compuertas-info') }}</p>
      <p>{{ $t('malbec-from-perdriel') }}<br>
        {{ $t('malbec-from-perdriel-info') }}</p>
      <p>{{ $t('malbec-from-agrelo') }}<br>
        {{ $t('malbec-from-agrelo-info') }}</p>
    <div class="final__line"></div>
    <h2>{{ $t('certifications') }}</h2>
    <img src="@/assets/img/certificates.png" alt="Certificates">
    <h2>{{ $t('accolades') }}</h2>
    <img src="@/assets/img/accolades.png" alt="Accolades">
    <div class="final__bottom">
      <a href="https://youtube.com/playlist?list=PL7SuCx8YGNWFQZCEfxfGll067NY17uC2E" target="_blank" class="btn">{{ $t('discover-the-golden-terroir') }}</a>
      <ul class="socials">
        <li><a href="https://www.instagram.com/triventowines/" target="_blank"><img src="@/assets/img/instagram.svg" alt=""></a></li>
        <li><a href="https://www.facebook.com/TriventoByV" target="_blank"><img src="@/assets/img/facebook.svg" alt=""></a></li>
        <li><a href="https://www.youtube.com/channel/UCI2Fxei5WymVNz20KmKBK2w" target="_blank"><img src="@/assets/img/youtube.svg" alt=""></a></li>
        <li><a href="https://triventoclub.com/" target="_blank"><img src="@/assets/img/trivento.svg" alt=""></a></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Final',
  components: {},
  data() {
    return {}
  },
  mounted() {

  },
  methods: {}
}
</script>

