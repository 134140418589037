<template>
  <div class="locale-changer language-list">
    <img
        class="language-list__arrow"
        src="@/assets/img/icon-arrow-down.svg"
        alt="arrow"
    />

    <select
        v-if="type === 'select'"
        v-model="$i18n.locale"
        class="select"
        @change="switchLocale($i18n.locale)"
    >
      <option v-for="(locale, i) in locales" :key="`Lang${i}`" :value="locale" :selected='option'>
        {{ locale }}
      </option>
    </select>
    <ul v-else-if="type === 'list'">
      <li v-for="locale in locales" :key="locale" @click="switchLocale(locale)">
        {{ locale }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SwitchLocale",
  props: {
    type: {
      type: String,
      validator: val => ["select", "list"].includes(val),
      default: "select"
    }
  },
  data() {
    return {
      locales: ['en','es'],
      option: ''
    };
  },
  methods: {
    switchLocale(lang) {
      for (let i = 0; i < this.locales.length; i++) {
        if (lang === this.locales[i]) {
          this.$i18n.locale = this.locales[i];
          console.log(this.$i18n.locale);
        }
      }
    },
  }
};
</script>
