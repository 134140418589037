<template>
  <div class="compass-container" v-if="!compassActive">
    <div class="compass__heading">
      <h2>{{ $t('raise-your-glasses') }}</h2>
      <p>{{ $t('to-the-land-of') }}<br/>
        <span>{{ $t('the') }}</span> {{ $t('malbec') }}</p>
    </div>
    <div class="compass compass--static">
      <div class="compass-circle"></div>
    </div>
    <button class="btn start-btn" @click="goToNextStep">{{ $t('discover-golden-reserve') }}</button>
  </div>
  <div class="compass-container" v-else>
    <div class="compass__heading">
      <h2>{{ $t('set-the-compass') }}</h2>
    </div>
    <div class="compass">
      <div class="arrow"></div>
      <div class="compass-circle-wrapper">
        <div class="compass-circle"></div>
      </div>
      <div class="my-point"></div>
    </div>
    <button class="btn start-btn">{{ $t('start-compass') }}</button>
  </div>
</template>

<script>
export default {
  name: 'Compass',
  components: {},
  data() {
    return {
      compassActive: true,
    }
  },
  mounted() {
    const compassCircle = document.querySelector(".compass-circle");
    const compassCircleWrapper = document.querySelector(".compass-circle-wrapper");
    const startBtn = document.querySelector(".start-btn");
    const myPoint = document.querySelector(".my-point");

    let compass;
    const isIOS = !(
        navigator.userAgent.match(/(iPod|iPhone|iPad)/) &&
        navigator.userAgent.match(/AppleWebKit/)
    );
    const disableCompass = (() => {
      this.setStaticCompass();
    });

    function startCompass() {
      if (!isIOS) {
        DeviceOrientationEvent.requestPermission()
            .then((response) => {
              if (response === "granted") {
                window.addEventListener("deviceorientation", handler, true);
                setTimeout(function(){
                  compassCircleWrapper.classList.add('compass-circle-wrapper--active');
                }, 50);
              } else {
                alert('Has not to be allowed.');
              }
            })
            .catch(() => alert("not supported"));
      } else {
        window.addEventListener("deviceorientationabsolute", handler, true);
      }
    }

    init();

    function init() {
      startBtn.addEventListener("click", startCompass);
    }

    function handler(e) {
      compass = e.webkitCompassHeading || Math.abs(e.alpha - 360);
      compassCircle.style.transform = `translate(-50%, -50%) rotate(${-compass}deg)`;
      // ±15 degree

      setTimeout(function () {
        if (Math.abs(compass) <= 335 && Math.abs(compass) >= 330) {
          disableCompass();
        }
      }, 200);
    }



  },
  methods: {
    setStaticCompass() {
      setTimeout(() => {
        this.compassActive = false;
      }, 500);
    },
    goToNextStep() {
      this.$emit('updateStep');
    }
  }
}
</script>

